export default {
  "app-address-select-modal": () => import("/home/node/app/src/layouts/app/AddressSelectModal.vue").then(m => m.default || m),
  "app-layout-auth-popover": () => import("/home/node/app/src/layouts/app/AppLayoutAuthPopover.vue").then(m => m.default || m),
  "app-layout-catalog-menu": () => import("/home/node/app/src/layouts/app/AppLayoutCatalogMenu.vue").then(m => m.default || m),
  "app-layout-footer": () => import("/home/node/app/src/layouts/app/AppLayoutFooter.vue").then(m => m.default || m),
  "app-layout-footer-app-block": () => import("/home/node/app/src/layouts/app/AppLayoutFooterAppBlock.vue").then(m => m.default || m),
  "app-layout-footer-link-group": () => import("/home/node/app/src/layouts/app/AppLayoutFooterLinkGroup.vue").then(m => m.default || m),
  "app-layout-header": () => import("/home/node/app/src/layouts/app/AppLayoutHeader.vue").then(m => m.default || m),
  "app-layout-mobile-search-screen": () => import("/home/node/app/src/layouts/app/AppLayoutMobileSearchScreen.vue").then(m => m.default || m),
  "app-layout-profile-popover": () => import("/home/node/app/src/layouts/app/AppLayoutProfilePopover.vue").then(m => m.default || m),
  "app-layout-search-bar": () => import("/home/node/app/src/layouts/app/AppLayoutSearchBar.vue").then(m => m.default || m),
  "app-layout-tab-bar": () => import("/home/node/app/src/layouts/app/AppLayoutTabBar.vue").then(m => m.default || m),
  "app-layout-web-view-header": () => import("/home/node/app/src/layouts/app/AppLayoutWebViewHeader.vue").then(m => m.default || m),
  "app-ask-question-block": () => import("/home/node/app/src/layouts/app/AskQuestionBlock.vue").then(m => m.default || m),
  "app-chat-button": () => import("/home/node/app/src/layouts/app/ChatButton.vue").then(m => m.default || m),
  "app-simple-map": () => import("/home/node/app/src/layouts/app/SimpleMap.vue").then(m => m.default || m),
  app: () => import("/home/node/app/src/layouts/app/index.vue").then(m => m.default || m),
  empty: () => import("/home/node/app/src/layouts/empty.vue").then(m => m.default || m),
  "landing-layout-footer": () => import("/home/node/app/src/layouts/landing/LandingLayoutFooter.vue").then(m => m.default || m),
  "landing-layout-header": () => import("/home/node/app/src/layouts/landing/LandingLayoutHeader.vue").then(m => m.default || m),
  landing: () => import("/home/node/app/src/layouts/landing/index.vue").then(m => m.default || m)
}