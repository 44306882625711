import revive_payload_client_tpftKHvMCD from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tcBpQNaYRs from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NApEHBTu8f from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_tRrnT26f3M from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_PJ7woMAxCS from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Zpal0NxmK1 from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@4.9.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/app/.nuxt/components.plugin.mjs";
import prefetch_client_233kdK9V2e from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_eGSNsbr1YZ from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.58.9_eslint@8.57.0_flo_rw277jtp65bfbt2fzxvabuina4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import head_0tN8WUbtKU from "/home/node/app/src/plugins/head.ts";
import urql_v08Sn7DSyh from "/home/node/app/src/plugins/urql.ts";
import http_hKQ5SktjNW from "/home/node/app/node_modules/.pnpm/@glow+shared@1.26.0_typescript@4.9.5/node_modules/@glow/shared/plugins/http.ts";
import vue_query_0zjSCNNlwA from "/home/node/app/node_modules/.pnpm/@glow+shared@1.26.0_typescript@4.9.5/node_modules/@glow/shared/plugins/vue-query.ts";
import locale_KQbdRChXH8 from "/home/node/app/node_modules/.pnpm/@glow+shared@1.26.0_typescript@4.9.5/node_modules/@glow/shared/plugins/locale.ts";
export default [
  revive_payload_client_tpftKHvMCD,
  unhead_tcBpQNaYRs,
  router_NApEHBTu8f,
  payload_client_tRrnT26f3M,
  check_outdated_build_client_PJ7woMAxCS,
  plugin_vue3_Zpal0NxmK1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_233kdK9V2e,
  chunk_reload_client_eGSNsbr1YZ,
  head_0tN8WUbtKU,
  urql_v08Sn7DSyh,
  http_hKQ5SktjNW,
  vue_query_0zjSCNNlwA,
  locale_KQbdRChXH8
]