import { default as blankveiZ2eEcZ6Meta } from "/home/node/app/src/pages/blank.vue?macro=true";
import { default as client7VKUuByfZcMeta } from "/home/node/app/src/features/landing/pages/client.vue?macro=true";
import { default as masteriRNF392abwMeta } from "/home/node/app/src/features/landing/pages/master.vue?macro=true";
import { default as register_45helpOlwcHSXNeYMeta } from "/home/node/app/src/features/landing/pages/register-help.vue?macro=true";
import { default as homel4E4gAmlQNMeta } from "/home/node/app/src/features/home/pages/home.vue?macro=true";
import { default as chatKjH0INyIXvMeta } from "/home/node/app/src/features/chat/pages/chat.vue?macro=true";
import { default as profilelsHGxofoM8Meta } from "/home/node/app/src/features/profile/pages/profile.vue?macro=true";
import { default as catalogqlbvRS5YHhMeta } from "/home/node/app/src/features/partners/pages/catalog.vue?macro=true";
import { default as detailsB6kYVAv25SMeta } from "/home/node/app/src/features/partners/pages/details.vue?macro=true";
import { default as specializations7Wt5R2HuaQMeta } from "/home/node/app/src/features/specializations/pages/specializations.vue?macro=true";
import { default as servicesxVxlOhmsylMeta } from "/home/node/app/src/features/services/pages/services.vue?macro=true";
import { default as partner_45servicepxEEsJwHfJMeta } from "/home/node/app/src/features/services/pages/partner-service.vue?macro=true";
import { default as cartRoCiiapa0WMeta } from "/home/node/app/src/features/cart/pages/cart.vue?macro=true";
export default [
  {
    name: blankveiZ2eEcZ6Meta?.name ?? "blank",
    path: blankveiZ2eEcZ6Meta?.path ?? "/blank",
    meta: blankveiZ2eEcZ6Meta || {},
    alias: blankveiZ2eEcZ6Meta?.alias || [],
    redirect: blankveiZ2eEcZ6Meta?.redirect,
    component: () => import("/home/node/app/src/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: client7VKUuByfZcMeta?.name ?? "Client landing",
    path: client7VKUuByfZcMeta?.path ?? "/client",
    meta: client7VKUuByfZcMeta || {},
    alias: client7VKUuByfZcMeta?.alias || [],
    redirect: client7VKUuByfZcMeta?.redirect,
    component: () => import("/home/node/app/src/features/landing/pages/client.vue").then(m => m.default || m)
  },
  {
    name: masteriRNF392abwMeta?.name ?? "Master landing",
    path: masteriRNF392abwMeta?.path ?? "/master",
    meta: masteriRNF392abwMeta || {},
    alias: masteriRNF392abwMeta?.alias || [],
    redirect: masteriRNF392abwMeta?.redirect,
    component: () => import("/home/node/app/src/features/landing/pages/master.vue").then(m => m.default || m)
  },
  {
    name: register_45helpOlwcHSXNeYMeta?.name ?? "Register help landing",
    path: register_45helpOlwcHSXNeYMeta?.path ?? "/master/register-help",
    meta: register_45helpOlwcHSXNeYMeta || {},
    alias: register_45helpOlwcHSXNeYMeta?.alias || [],
    redirect: register_45helpOlwcHSXNeYMeta?.redirect,
    component: () => import("/home/node/app/src/features/landing/pages/register-help.vue").then(m => m.default || m)
  },
  {
    name: homel4E4gAmlQNMeta?.name ?? "Client home",
    path: homel4E4gAmlQNMeta?.path ?? "/",
    meta: homel4E4gAmlQNMeta || {},
    alias: homel4E4gAmlQNMeta?.alias || [],
    redirect: homel4E4gAmlQNMeta?.redirect,
    component: () => import("/home/node/app/src/features/home/pages/home.vue").then(m => m.default || m)
  },
  {
    name: chatKjH0INyIXvMeta?.name ?? undefined,
    path: chatKjH0INyIXvMeta?.path ?? "/chat",
    meta: chatKjH0INyIXvMeta || {},
    alias: chatKjH0INyIXvMeta?.alias || [],
    redirect: chatKjH0INyIXvMeta?.redirect,
    component: () => import("/home/node/app/src/features/chat/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: profilelsHGxofoM8Meta?.name ?? "Profile",
    path: profilelsHGxofoM8Meta?.path ?? "/profile",
    meta: profilelsHGxofoM8Meta || {},
    alias: profilelsHGxofoM8Meta?.alias || [],
    redirect: profilelsHGxofoM8Meta?.redirect,
    component: () => import("/home/node/app/src/features/profile/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: catalogqlbvRS5YHhMeta?.name ?? "Partner catalog",
    path: catalogqlbvRS5YHhMeta?.path ?? "/catalog/:city?/:specialization?/:service?/:type(masters|companies)",
    meta: catalogqlbvRS5YHhMeta || {},
    alias: catalogqlbvRS5YHhMeta?.alias || [],
    redirect: catalogqlbvRS5YHhMeta?.redirect,
    component: () => import("/home/node/app/src/features/partners/pages/catalog.vue").then(m => m.default || m)
  },
  {
    name: detailsB6kYVAv25SMeta?.name ?? "Partner details",
    path: detailsB6kYVAv25SMeta?.path ?? "/catalog/:city/:type(masters|companies)/:partner",
    meta: detailsB6kYVAv25SMeta || {},
    alias: detailsB6kYVAv25SMeta?.alias || [],
    redirect: detailsB6kYVAv25SMeta?.redirect,
    component: () => import("/home/node/app/src/features/partners/pages/details.vue").then(m => m.default || m)
  },
  {
    name: specializations7Wt5R2HuaQMeta?.name ?? "Specializations",
    path: specializations7Wt5R2HuaQMeta?.path ?? "/catalog",
    meta: specializations7Wt5R2HuaQMeta || {},
    alias: specializations7Wt5R2HuaQMeta?.alias || [],
    redirect: specializations7Wt5R2HuaQMeta?.redirect,
    component: () => import("/home/node/app/src/features/specializations/pages/specializations.vue").then(m => m.default || m)
  },
  {
    name: servicesxVxlOhmsylMeta?.name ?? "Services",
    path: servicesxVxlOhmsylMeta?.path ?? "/catalog/:specialization",
    meta: servicesxVxlOhmsylMeta || {},
    alias: servicesxVxlOhmsylMeta?.alias || [],
    redirect: servicesxVxlOhmsylMeta?.redirect,
    component: () => import("/home/node/app/src/features/services/pages/services.vue").then(m => m.default || m)
  },
  {
    name: partner_45servicepxEEsJwHfJMeta?.name ?? "Partner service",
    path: partner_45servicepxEEsJwHfJMeta?.path ?? "/catalog/:city/:type(masters|companies)/:partner/services/:service",
    meta: partner_45servicepxEEsJwHfJMeta || {},
    alias: partner_45servicepxEEsJwHfJMeta?.alias || [],
    redirect: partner_45servicepxEEsJwHfJMeta?.redirect,
    component: () => import("/home/node/app/src/features/services/pages/partner-service.vue").then(m => m.default || m)
  },
  {
    name: cartRoCiiapa0WMeta?.name ?? undefined,
    path: cartRoCiiapa0WMeta?.path ?? "/cart",
    meta: cartRoCiiapa0WMeta || {},
    alias: cartRoCiiapa0WMeta?.alias || [],
    redirect: cartRoCiiapa0WMeta?.redirect,
    component: () => import("/home/node/app/src/features/cart/pages/cart.vue").then(m => m.default || m)
  }
]