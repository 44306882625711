import urql, {
  type SSRData,
  Client,
  fetchExchange,
  ssrExchange,
} from '@urql/vue';
import {
  cacheExchange,
  type OptimisticMutationConfig,
  type UpdatesConfig,
} from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';
// import {
//   UpdateMasterSpecializationInput,
//   UpdateUserAvatarInput,
//   UpdateUserInput,
// } from '#shared/gql/graphql';
import schema from '@/gql/introspection.json';
import {
  defineNuxtPlugin,
  useRequestHeaders,
  useRuntimeConfig,
  useState,
} from 'nuxt/app';

export default defineNuxtPlugin((nuxt) => {
  const { apiGraphqlUrl } = useRuntimeConfig().public;
  const urqlState = useState<SSRData | undefined>('urql-data');
  const isClient = !!process.client;
  const ssr = ssrExchange({
    isClient,
    initialState: isClient ? urqlState.value : undefined,
  });
  const headers = useRequestHeaders();
  const fetchOptions: RequestInit = isClient
    ? { credentials: 'include' }
    : {
        headers: {
          Cookie: headers.cookie?.toString(),
          'X-Forwarded-For': headers['x-forwarded-for'],
          'X-Real-Ip': headers['x-real-ip'],
        },
      };

  const updates: UpdatesConfig = {
    Mutation: {},
  };

  const optimistic: OptimisticMutationConfig = {};

  nuxt.vueApp.use(
    urql,
    new Client({
      url: apiGraphqlUrl,
      fetchOptions,
      exchanges: [
        devtoolsExchange,
        cacheExchange({
          schema,
          updates,
          optimistic,
        }),
        ssr,
        fetchExchange,
      ],
    })
  );

  if (!isClient)
    nuxt.hooks.hook('app:rendered', () => {
      const data = ssr.extractData();
      urqlState.value = data;
    });
});
